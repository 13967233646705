<template>
  <div class="col-lg-3">
    <!-- Simple card -->
    <b-card style="border-radius: 13px">
      <router-link
        :to="`/games/quiz/${this.quiz.id}`"
        style="color: #303030"
        @click="saveQuiz()"
      >
        <div>
          <img
            class="card-img"
            :src="`${this.quiz.urlImagePortrait}`"
            alt="Card image"
            style="object-fit: cover; opacity: 0.9"
          />
        </div>

        <b-card-title>
          <h5
            style="
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              line-clamp: 1;
              -webkit-box-orient: vertical;
              text-align: center;
            "
          >
            {{ quiz.name }}
          </h5>
        </b-card-title>
        <b-card-text>
          <h4 style="text-align: center">
            {{ time }}
          </h4>
        </b-card-text>
      </router-link>
      <div style="display: flex; align-items: center; justify-content: center">
        <b-button class="c2play-primary">
          <b-dropdown
            class="card-drop"
            variant="white"
            menu-class="dropdown-menu-end"
            right
            toggle-class="p-0"
          >
            <template v-slot:button-content>
              <span style="color: #fff"
                ><i class="mdi mdi-dots-horizontal font-size-18"></i
              ></span>
            </template>
            <b-dropdown-item @click="showUpdateCat()">{{
              $t("pages.videos.actions.modifier")
            }}</b-dropdown-item>
            <b-dropdown-item @click="confirm()">{{
              $t("pages.videos.actions.supprimer")
            }}</b-dropdown-item>
          </b-dropdown>
        </b-button>
      </div>
    </b-card>

    <b-modal
      v-model="modalUpdateQuiz"
      id="modal-xl"
      size="xl"
      title="Modifier le quiz"
      title-class="font-18"
      hide-footer
    >
      <!-- <div class="card" style="padding: 25px; width: 70%; margin-left: auto; margin-right: auto; border-radius: 15px;"> -->

      <div class="card-body">
        <b-alert
          v-model="isAuthError"
          variant="danger"
          class="mt-1"
          dismissible
        >
          {{ authError }}
        </b-alert>

        <form>
          <div class="form-group row mb-2">
            <div class="col-lg-6">
              <label for="projectname" class="col-form-label">{{
                $t("pages.videos.formulaire.titre.label")
              }}</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <input
                id="projectname"
                name="projectname"
                v-model="quizElement.name"
                type="text"
                class="form-control"
                placeholder="Entrez le titre du quiz"
              />
            </div>

            <div class="form-group col-lg-6 mb-3">
              <label class="col-form-label">Prix</label>
              <div class="col-lg-12">
                <input
                  id="projectname"
                  v-model="quizElement.price"
                  name="projectname"
                  type="text"
                  class="form-control"
                  placeholder="Entrez le prix du vote"
                />
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="form-group col-lg-4">
              <label for="projectname" class="col-form-label">
                Date de début</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <input
                  id="projectname"
                  v-model="quizElement.startDate"
                  name="projectname"
                  type="datetime-local"
                  class="form-control"
                  placeholder="Entre le lien de la vidéo..."
                />
              </div>
            </div>

            <div class="form-group col-lg-4">
              <label for="projectname" class="col-form-label"
                >Date de publication des résultat</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <input
                  id="projectname"
                  v-model="quizElement.publishDate"
                  name="projectname"
                  type="datetime-local"
                  class="form-control"
                  placeholder="Entre le lien de la vidéo..."
                />
              </div>
            </div>

            <div class="form-group col-lg-4">
              <label for="projectname" class="col-form-label">Date de fin</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <input
                  id="projectname"
                  v-model="quizElement.endDate"
                  name="projectname"
                  type="datetime-local"
                  class="form-control"
                  placeholder="Entre le lien de la vidéo..."
                />
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="form-group col-lg-6">
              <label for="projectname" class="col-form-label"
                >{{ $t("pages.videos.formulaire.files.image") }} portrait</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="file"
                  @change="imgVideoSelect($event)"
                  class="form-control"
                  placeholder="Entre le lien de la vidéo..."
                  accept=".png, .jpeg, .jpg"
                />
              </div>
            </div>

            <div class="form-group col-lg-6">
              <label for="projectname" class="col-form-label"
                >Image paysage</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="file"
                  @change="imgVideoSelectPaysage($event)"
                  class="form-control"
                  placeholder="Entre le lien de la vidéo..."
                  accept=".png, .jpeg, .jpg"
                />
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="form-group col-lg-6">
              <label for="projectname" class="col-form-label"
                >Logo du sponsort</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="file"
                  @change="imgSponsor($event)"
                  class="form-control"
                  placeholder="Entre le lien de la vidéo..."
                  accept=".png, .jpeg, .jpg"
                />
              </div>
            </div>

            <div class="col-lg-6">
              <label class="col-form-label"
                >Sélectionnez un pourcentage de validation de quiz</label
              >
              <span style="color: red; font-size: 1.2em">*</span>
              <div class="form-control">
                <select class="tags-input_text" v-model="quizElement.taux">
                  <option value="50">50 %</option>
                  <option value="60">60 %</option>
                  <option value="70">70 %</option>
                  <option value="80">80 %</option>
                  <option value="90">90 %</option>
                  <option value="100">100 %</option>
                </select>
                <!-- <input type="text" :placeholder="$t('pages.videos.formulaire.tags.placeholder')" @keydown="addTags" @keydown.delete="removeLastTag" class="tags-input_text"> -->
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="form-group col-lg-6">
              <label for="projectname" class="col-form-label">
                Est-il gratuit</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <div class="display">
                  <div style="margin-right: 20px">
                    <b-form-radio
                      v-model="quizElement.isFree"
                      name="some-radios1"
                      value="true"
                      >Oui</b-form-radio
                    >
                  </div>
                  <div>
                    <b-form-radio
                      v-model="quizElement.isFree"
                      name="some-radios1"
                      value="false"
                      >Non</b-form-radio
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group col-lg-6">
              <label for="projectname" class="col-form-label"
                >Peut-on tenter plusieurs fois candidats</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <div class="display">
                  <div style="margin-right: 20px">
                    <b-form-radio
                      v-model="quizElement.isMultiAttent"
                      name="some-radios3"
                      value="true"
                      >Oui</b-form-radio
                    >
                  </div>
                  <div>
                    <b-form-radio
                      v-model="quizElement.isMultiAttent"
                      name="some-radios3"
                      value="false"
                      >Non</b-form-radio
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div class="row">
          <div class="col-lg-12" style="margin-top: 10px">
            <button
              class="btn c2play-primary"
              @click="updateQuiz"
              style="position: relative; left: 45%"
            >
              Modifier
            </button>
          </div>
        </div>
      </div>
    </b-modal>

    <loaderProcessVue
      :title="titre"
      :progress="progress"
      :visible="process"
    ></loaderProcessVue>
  </div>
</template>

<script>
import loaderProcessVue from "../../../components/widgets/loaderProcess.vue";
import Swal from "sweetalert2";
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
export default {
  components: {
    loaderProcessVue,
  },
  data() {
    return {
      titre: "",
      progress: 0,
      process: false,
      name: "",
      time: "",
      modalUpdateQuiz: false,
      quizElement: {
        name: "",
        indication: "",
        price: 0,
        isFree: false,
        isMultiChoice: false,
        isMultiAttent: false,
        startDate: "",
        endDate: "",
        taux: 0,
        imgPaysage: null,
        imgSelect: null,
        sponsorLogo: null,
        publishDate: "",
      },
    };
  },
  props: {
    quiz: {
      type: Object,
      required: true,
    },
  },
  methods: {
    creerMinuteur(dateDebut, dateFin) {
      const differenceEnMillisecondes = dateFin - dateDebut;
      const differenceEnSecondes = Math.floor(differenceEnMillisecondes / 1000);

      let tempsRestant = differenceEnSecondes;
      let minuteur;

      const afficherTempsRestant = (temps) => {
        const jours = Math.floor(temps / (3600 * 24));
        temps %= 3600 * 24;
        const heures = Math.floor(temps / 3600);
        temps %= 3600;
        const minutes = Math.floor(temps / 60);
        const secondes = temps % 60;

        const tempsRestantFormat =
          jours + "j " + heures + "h " + minutes + "m " + secondes + "s";

        // console.log(`Temps restant : ${tempsRestantFormat}`);
        this.time = tempsRestantFormat;
      };

      const demarrerMinuteur = () => {
        minuteur = setInterval(() => {
          if (tempsRestant > 0) {
            tempsRestant--;
            afficherTempsRestant(tempsRestant);
          } else {
            clearInterval(minuteur);
            this.time = "00j 00h 00m 00s";
            console.log("Minuteur terminé !");
          }
        }, 1000); // Interval d'une seconde (1000 ms)
      };

      // Pour démarrer le minuteur
      demarrerMinuteur();
    },
    saveQuiz() {
      localStorage.setItem("quiz", JSON.stringify(this.quiz));
    },
    showUpdateCat() {
      this.modalUpdateQuiz = true;
    },
    imgVideoSelect(event) {
      this.quizElement.imgSelect = event.target.files[0]; // Get the selected image or video
      console.log(this.quizElement.imgSelect);
    },

    imgVideoSelectPaysage(event) {
      this.quizElement.imgPaysage = event.target.files[0];
      console.log(this.quizElement.imgPaysage);
    },

    imgSponsor(event) {
      this.quizElement.sponsorLogo = event.target.files[0];
      console.log(this.quizElement.sponsorLogo);
    },

    updateQuiz() {
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        console.log(`Upload progress: ${this.progress}%`);
      };

      this.process = true;
      this.modalUpdateQuiz = false;
      this.titre = "Modification du quiz";
      console.log(this.quizElement);
      Api.patchFormData(
        `/games/rest/quizz/update/${this.quiz.id}`,
        {
          imagePaysage: this.quizElement.imgPaysage,
          imagePortrait: this.quizElement.imgSelect,
          logoSponsor: this.quizElement.sponsorLogo,
          name: this.quizElement.name,
          price: this.quizElement.price,
          isFree: this.quizElement.isFree,
          isMultiChoice: this.quizElement.isMultiAttent,
          isMultiAttent: this.quizElement.isMultiAttent,
          startDate: this.quizElement.startDate,
          endDate: this.quizElement.endDate,
          taux: this.quizElement.taux,
          indication: "test",
          releaseDate: this.quizElement.publishDate,
        },
        { onUploadProgress }
      )
        .then(() => {
          this.process = false;
          Swal.fire("Succes!", "Quiz modifié avec succès", "success");
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },
    confirm() {
      Swal.fire({
        title: "Voulez-vous vraiment supprimer ce quiz ?",
        // text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui",
      }).then((result) => {
        if (result.value) {
          // alert(id)
          this.deleteQuiz();
          // location.reload();
        }
      });
    },
    deleteQuiz() {
      Api.delete(`/games/rest/quizz/delete/${this.quiz.id}`)
        .then((response) => {
          console.log(response);
          Swal.fire(
            "Deleted!",
            "Votre quiz a été supprimé avec succès",
            "success"
          );
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },
  },
  mounted() {
    this.quizElement.name = this.quiz.name;
    this.quizElement.price = this.quiz.price;
    this.quizElement.isFree = this.quiz.isFree;
    this.quizElement.isMultiAttent = this.quiz.isMultiAttent;
    this.quizElement.isMultiChoice = this.quiz.isMultiChoice;
    this.quizElement.startDate = this.quiz.startDate;
    this.quizElement.publishDate = this.quiz.releaseDate;
    this.quizElement.endDate = this.quiz.endDate;
    this.quizElement.taux = this.quiz.quota;

    const dateDebut = new Date();
    const dateFin = new Date(this.quiz.endDate);
    this.creerMinuteur(dateDebut, dateFin);
  },
};
</script>

<style>
.card-img {
  object-fit: cover;
  height: 200px;
  width: 100%;
  border-radius: 9px;
  margin-bottom: 20px;
}
</style>
